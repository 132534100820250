<template>
    <div class="loan-record">
       <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">我的贷款记录</div>
       <div class="navHeader flex">
        <div class="search-box flex">          
          <input type="text" placeholder="请输入搜索关键词" v-model="searchVal" @keyup.enter="searchSubmit">
          <img src="@/assets/img/serch.png" @click="searchSubmit">
        </div>
      </div>
      <div v-if="list.length === 0" class="blank">
            <img src="@/assets/img/gxm2.png" alt="">
            <p>贷款记录为空~</p>  
        </div> 
        <div v-else class="card" v-for="item in list" :key="item">
            <div class="nums">需求编号：{{item.requireId}}</div>
            <div class="company">{{item.enterName}}</div>
            <div class="details flex-wrap">
                <div>是否定向：{{item.orientRequireFlag==="0" ? '定向' : '不定向'}}</div>
                <div>申请人：{{item.applicationUserDescr}}</div>
                <div>是否终止受理：{{item.isTerminationFlag==="0" ? '是' : '否'}}</div>
                <div>提交时间：{{ item.applicationTime }}</div>
            </div>
            <div class="flex">
                <div @click="goProgress(item.requireId)" class="plan">
                <img src="../assets/img/record_icon1.png" alt="">
                <p>查看进度</p>
                </div>
                <div class="plan need" @click="goDemandContent(item.requireId)">
                <img src="../assets/img/record_icon2.png" alt="">
                <p>需求详情</p>
                </div>
                <div v-show="item.firCheckFlag=='1'" style="display:none" class="plan break" @click="endLoan(item.requireId)">
                <img src="../assets/img/progress_icon.png" alt="">
                <p>终止受理</p>
                </div>
            </div>            
        </div>
    
        <!-- <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        >
            <div class="card" v-for="item in list" :key="item">
                <div class="nums">需求编号：10955</div>
                <div class="company">南宁金电图腾软件有限公司</div>
                <div class="details flex-wrap">
                    <div>是否定向：定向</div>
                    <div>申请人：李四</div>
                    <div>是否公开：不公开</div>
                    <div>提交时间：2021-10-08 19:45:04</div>
                </div>
                <div class="flex">
                <router-link to="/loanProgressContent" class="plan flex">
                <img src="../assets/img/record_icon1.png" alt="">
                <p>查看进度</p>
                </router-link>
                <router-link to="/loanDemandContent" class="need flex">
                <img src="../assets/img/record_icon2.png" alt="">
                <p>需求详情</p>
                </router-link>
            </div> 
                
            </div>
        </van-list> -->
    </div>
</template>
<script>
import { ref,computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Toast,Dialog } from 'vant';
import { apiRequireList,apiEndFirCheck } from '../api/axios';
export default {
    setup() {
        let store = useStore();
        const list = ref([]);
        let router = useRouter();
        let requirementList = [];
        const loading = ref(false);
        const finished = ref(false);
         apiRequireList({}).then(res => {               
             if(res.code == '1'){
                //  res.info.creditProInfo.forEach(e => {
                //   creditProInfo.push(e);
                // });
                
                list.value = res.info.requirementList;
                // requirementList = res.info.requirementList;
              }
              else {
                Toast(res.msg);
              }
          });      
        const onLoad = () => {
        //      apiRequireList({}).then(res => {
        //      console.log(res,'返回的数据')                   
        //      if(res.code == '1'){
        //          res.info.creditProInfo.forEach(e => {
        //           creditProInfo.push(e);
        //         });                
        //         if(apiProductListInfo.isFirst == '1') {
        //           store.dispatch('setQueryInfo',res.info.queryInfo);
        //           totalPage.value = res.info.page.totalPage;
        //           apiProductListInfo.isFirst = '0';
        //           finished.value = false;//加载设置为未完成
        //         }
        //         // 数据全部加载完成
        //         if(apiProductListInfo.pageNo >= totalPage.value){
        //           finished.value = true;
        //         }else {
        //           apiProductListInfo.pageNo++;
        //           // 加载状态结束
        //           loading.value = false;
        //         }
        //       }
        //       else {
        //         Toast(res.msg);
        //       }
        //   });  
        };

        //搜索框键入
        const searchVal = ref('');
        const searchSubmit = () => {
          console.log('搜索值为：',searchVal.value);
        //   console.log(requirementList,55)
        //   list.value.splice(0,list.value.length);//清空原有的数据
        //   console.log(requirementList,8888)
        //   requirementList.forEach(e => {
        //       console.log(e.enterName.indexOf(searchVal.value),999)
        //       if(e.enterName.indexOf(searchVal.value)){
        //           list.value.push(e);
        //         }
        //   }); 
        };
        //时间戳转换
        // const dateFormat = computed(

        // )
        const dateFormat = (timestamp)=>{
            let date =  new Date(timestamp); 
            let fmt = 'yyyy-MM-dd hh:mm:ss';
            let o = {
                "M+": date.getMonth() + 1,
                "d+": date.getDate(),
                "h+": date.getHours(),
                "m+": date.getMinutes(),
                "s+": date.getSeconds(),
                "q+": Math.floor((date.getMonth() + 3) / 3),
                "S": date.getMilliseconds()
            };
            if (/(y+)/.test(fmt))
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (let k in o)
                if (new RegExp("(" + k + ")").test(fmt))
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        };
        const goProgress = (pId)=>{
            router.push({
            path: '/loanProgress',
            query: {
              requireId: pId
            }
          });
        };
        const goDemandContent = (dId)=>{
            router.push({
            path: '/loanDemandContent',
            query: {
              requireId: dId
            }
          });
        };
        // 终止受理
        const endLoan = (eId)=>{
            Dialog.confirm({
                title: '提示',
                message:'是否确认终止受理，需求编号【'+eId +'】，终止受理后不影响已经受理的流程！如果需要终止已经受理的流程，请点击【查看进度】按钮，进行终止流程操作。',
            })
            .then(() => {
                apiEndFirCheck({requireId: eId.toString()}).then(res => {                 
                 if(res.code == '1'){
                    let i = list.value.findIndex(item => item.requireId === eId);
                    Toast.success('终止受理成功！');
                    list.value[i].isTerminationFlag = "0";
                    list.value[i].firCheckFlag = "0";
                  }
                  else {
                    Toast.fail('终止受理失败！'+res.msg);
                  }
                });
            })
            .catch(() => {
                // on cancel
                console.log('取消终止受理！')
            });
            
        };
        const goBack = () =>{
            if(router.currentRoute.value.params.page == 'back') {
                router.go(-1);
            }else if(store.state.isiOS) {
                try {
                    window.webkit.messageHandlers.back.postMessage(null);
                } catch (e) {
                    alert('跳转返回ios失败');
                }
            }else {
                router.push({
                    name: 'User'
                })
            }
        }
        return {
            list,
            onLoad,
            loading,
            finished,
            searchVal,
            searchSubmit,
            dateFormat,
            goProgress,
            goDemandContent,
            endLoan,
            goBack
        };
    },

}
</script>
<style lang="less" scoped>
.loan-record {
    padding-top: 12.5vw;
    .blank {
        padding-top: 8vw;
        text-align: center;
        img {
            width: 35vw;
            height: auto;
        }
        p {
            font-size: 4vw;
            color: #666666;
            margin-top: 2vw;
        }
    }
    .card {
        .nums {
            display: inline-block;
            color: #ff9900;
            border: 1px solid #ff9900;
            border-radius: 1vw;
            font-size: 4vw;
            padding: 0 2vw;
        }
        .company {
            color: #6699ff;
            font-size: 4.8vw;
            font-weight: 500;
            padding-top: 2vw;
        }
        .details {
            width: 100%;
            font-size: 3.5vw;
            color: #666666;
            padding: 2vw 0;
            div:nth-child(2n) {
                width: 60%;
                margin: 1.5vw 0;
            }
            div:nth-child(2n-1) {
                width: 40%;
                margin: 1.5vw 0;
            }
        }
        .plan {
            display: flex;
            align-items: center;
            background: linear-gradient(to right, #48e7cb, #52c8f2);
            color: #fff;
            font-size: 4vw;
            padding: 2vw 3vw;
            border-radius: 4vw;
            margin-right: 1vw;
            img {
                height: 4.5vw;
                margin-right: 1vw;
            }
            p {
                line-height: 4vw;
            }
        }
        .need {
            background: linear-gradient(to right, #ff9600, #ff6900);
        }
        .break {
            background: linear-gradient(to right, #ff6532, #ff0201); 
            margin-right: 0;
        }
    }
}
</style>